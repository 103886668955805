#animated-arrows {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 22px;
  overflow: hidden;
  position: relative;
  border-width: 1px 0;
  border-color: grey;
  border-style: solid;

  div {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    align-items: center;
    width: auto;

    &.right {
      transform: translateX(-100%);
      animation: moveArrowRight 10s linear infinite;

      &:nth-child(1) {
        animation-delay: 5s;
      }
    }

    &.left {
      transform: translateX(100%);
      animation: moveArrowLeft 10s linear infinite;

      &:nth-child(2) {
        animation-delay: 5s;
      }
    }

    svg {
      margin: 0;
      padding: 0;
    }
  }
}

@keyframes moveArrowRight {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes moveArrowLeft {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}
