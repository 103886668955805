.ct-legend {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;

  li {
    position: relative;
    padding-left: 23px;
    margin-bottom: 3px;
    list-style-type: none;
    margin: 5px 20px 0;
  }

  li:before {
    cursor: pointer;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    content: '';
    border: 3px solid transparent;
    border-radius: 2px;
  }

  li.inactive:before {
    background: transparent;
  }

  &.ct-legend-inside {
    position: absolute;
    top: 0;
    right: 0;
  }

  .ct-series-0:before {
    // background-color: rgb(255, 255, 255);
    // border-color: rgba(255, 255, 255, 0.8);
    background-color: #ffd870;
    border-color: #ffd970e0;
  }

  .ct-series-1:before {
    // background-color: #f44336;
    // border-color: #f44336;
    background-color: #ed7902;
    border-color: #ed7702ea;
  }
}